body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --bg: #242526;
  --bg-accent: #484A4D;
  --text-color: #DADCE1;
  --nav-size: 60px;
  --border: 1px solid #474A4D;
  --border-radius: 8px;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: var(--text-color);
  text-decoration: none;
}

#root {
  height: 100%;
}

.app {
  background-color: var(--bg);
  height: 100%;
}

.navbar {
  height: var(--nav-size);
  background-color: var(--bg-accent);
  padding: 0 1rem;
}

.navbar-nav {
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.nav-item-container {
  width: calc(var(--nav-size) * 0.8);
  display: flex;
  align-items: center;
  justify-items: center;
}

.nav-item {
  --item-size: calc(var(--nav-size) * 0.5);
  width: var(--visual-width);
  height: var(--item-size);
  background-color: var(--bg);
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 5px;
  padding-right: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-item:hover {
  filter: brightness(1.2);
}

.item-visual {
  --visual-width: width;
}

.home-page {
  color: var(--text-color);
  margin: 3px;
  padding: 5px;
}

.downloads-page {
  color: var(--text-color);
  margin: 3px;
  padding: 2px;
}

.download-options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 5px;
  margin: 5px;
}

.download-container {
  background-color: var(--bg-accent);
  padding: 3px;
}

.other-links {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 50px;
  background-color: #000000;
  font-size: small;
}

.other-links ul {
  display: flex;
  flex-direction: row;
}

.other-links li {
  margin: 2px;
  padding: 2px;
}

.info ul {
  list-style: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  unicode-bidi: isolate;
}